import React from 'react'
import { Card, Col, Row } from 'antd';
import {CheckCircleOutlined, TrophyTwoTone, CrownTwoTone, TagTwoTone, StarTwoTone, setTwoToneColor} from '@ant-design/icons';

const item = (value) => (
<div style={{padding: "8px 12px", color:"#404040", display:'flex', flexDirection:'row', alignItems: 'flex-start', fontSize: 18, fontFamily: "Futura Medium"}}><CheckCircleOutlined style={{marginRight: 12, marginTop: 6}}/><div>{value}</div></div>
)

// const getTitleItem = (title) => {
//   // setTwoToneColor('#0d47a1');
//   let color = '#0d47a1'

//   let icon = <CheckCircleOutlined/>
//   switch(title.toLowerCase()) {
//     case "basic":
//       icon = <TagTwoTone/>
//       break;
//     case "premium":
//       color = '#1b5e20'
//       icon = <StarTwoTone/>
//       break;
//     case "organisation":
//       icon = <CrownTwoTone/>
//       break;
//     default:
//   }

//   setTwoToneColor(color)

//   return <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
//     {icon}
//     <div style={{marginLeft: 12, color: color}}>{title}</div>
//   </div>
// }

export default function Model({title, list}) {
  let colorLight = '#e3f2fd'
  let color = '#2196f3'
  let icon = <div/>
  switch(title.toLowerCase()) {
    case "basic":
      icon = <TagTwoTone/>
      break;
    case "premium":
      color = '#009688'
      colorLight = '#e0f2f1'
      icon = <StarTwoTone/>
      break;
    case "organisation":
      color = '#f44336'
      colorLight = '#ffebee'
      icon = <CrownTwoTone/>
      break;
    default:
  }

  setTwoToneColor(color)

  const titleItem = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
    {icon}
    <div style={{marginLeft: 12, color: color}}>{title}</div>
  </div>


  return (
    <Col sm={24} lg={8} style={{width: "100%", marginBottom: 24}}>
      <Card 
        bordered 
        hoverable 
        title={titleItem} 
        headStyle={{
          fontSize: 32, 
          fontFamily: "Futura Bold", 
          borderColor: color, 
          background: colorLight
        }}
        style={{borderColor: color}}
      >
        {list.map(value =>(
          item(value)
        ))}
      </Card>
    </Col>
  )
}
