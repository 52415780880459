import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import Seo from "../Seo"
import Model from "./Model"
import GettingStarted from '../Home/components/GetStarted';

import './pricing.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function Pricing({title, description}) {

  let location = null
  if (typeof window !== `undefined`){
    location = window.location
  }

  return (
    <>
    <Content className='pricing-root'>
      <Seo
        title={title}
        description={description}
        // image={`http:${post.heroImage.resize.src}`}
        type="Product"
        origin={location != null ? location.origin : null}
        pathname={location != null ? location.pathname : null}
      />
      <Col className='pricing-container' >
        <Row style={{alignItems: 'center'}}>
          <Typography>
            <Title className="title">Pricing models</Title>
            <Paragraph className='subtitle'>
              Know how your team works in the field. Empower your team, develop healthy work habits, and streamline processes to achieve exceptional results.
            </Paragraph>
          </Typography>
        </Row>
        <Row gutter={24} className='pricing-models'>
          <Model 
            title="Basic"
            list={[
              "Employees tracking",
              "Device status monitoring",
              "Attendances management",
              "Expenses management",
              "Reports management",
              "Google map for all apps",
              "Intuitive Android app",
              "Professional Supports"
            ]}
          />
          <Model 
            title="Premium"
            list={[
              "All in Basic",
              "Tasks management",
              "Clients/Sites management",
              "Custom task forms (2 nos)",
              "Offices and employees hierarchy management (optional)",
              "Integration with your existing ERP (optional)",
              "Survey forms (optional)"
            ]}
          />
          <Model 
            title="Organisation"
            list={[
              "Inhouse deployment",
              "All in Premium",
              "Offices and employees hierarchy management",
              "Integration with your existing ERP",
              "Existing features customisation",
              "New features development",
              "Service Operations",
              "Training and supports"
            ]}
          />
        </Row>
      </Col>
    </Content>
    <GettingStarted/>
    </>

  )
}